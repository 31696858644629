<template>
    <Header :style="{ overflowX: 'auto', padding:'0',background: 'transparent' }">
        <div id="headerNav">
            <span class="pointer" @click="goToHome">首页</span>
            <Dropdown class="dropdown-qrcode" trigger="click">
                <a href="javascript:void(0)">
                    小程序
                </a>
                <DropdownMenu slot="list">
                    <div class="qrcode-wrap">
                        <img src="~assets/qrcode.jpeg">
                        <div class="info">
                            <p>扫一扫</p>
                            <p>投屏如此简单</p>
                        </div>
                    </div>
                </DropdownMenu>
            </Dropdown>
            <span v-if="!is_org" class="upgrade pointer" @click="handleUpgrade">升级成企业</span>
            <span v-allow="'account,user'" v-if="is_org" class="campony-center" @click="goTo3('organization')">企业中心</span>
            <!-- <Badge :count="messages_count" class="badge-wrap" @click.native="goTo('messages')">
                <a href="#" class="badge-text">消息</a>
            </Badge> -->
            <Avatar v-if="avatar" class="layout-avatar" icon="ios-person" :src="`/cym/${avatar}`" />
            <Dropdown trigger="click" style="margin-left: 14px;margin-right: 10px" placement="bottom-end">

                <a class="username line-overflow" href="javascript:void(0)">
                    {{ username }}
                    <Icon type="md-arrow-dropdown"/>
                </a>
                <DropdownMenu slot="list">
                    <DropdownItem @click.native="goTo('personalCenter')">个人中心</DropdownItem>
                    <DropdownItem v-if="organizationList.length !== 0" @click.native="handleChangeCount">切换账号</DropdownItem>
                    <DropdownItem @click.native="logout">{{ $t('layout.logOut') }}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
        <upgrade-modal
            :show="upgradeModalShow"
            @cancel="handleUpgradeCancel"
            @confirm="handleUpgrageConfirm"
        ></upgrade-modal>
        <count-modal
            :show="countModalShow"
            :organizationList="organizationList"
            @cancel="hideCountModal"
        ></count-modal>
    </Header>  
</template>
<script>
    import { navigation, login } from '@/mixins/index'
    import upgradeModal from '@/views/components/upgradeModal'
    import countModal from './countModal'

    export default {
        name: "",
        mixins: [navigation, login],
        components: {
            upgradeModal,
            countModal
        },
        props: [],
        data () {
            return {
                upgradeModalShow: false,
                countModalShow: false,
                organizationList: []
            }
        },
        computed: {
            messages_count() {
                return this.$store.state.unread_count
            },
            avatar() {
                return this.$store.state.avatar_uri
            },
            username() {
                return this.$store.state.username
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            }
        },
        methods: {
            logout: function () {
                localStorage.removeItem('account')
                localStorage.removeItem('organization_id')
                this.$router.replace('/login')
                this.$store.dispatch('Logout')
            },
            handleUpgrade() {
                this.upgradeModalShow = true
            },
            handleUpgradeCancel() {
                this.upgradeModalShow = false
            },
            handleUpgrageConfirm() {

            },
            handleChangeCount() {
                this.countModalShow = true
            },
            hideCountModal() {
                this.countModalShow = false
            },
            goToHome() {
                let name = 'personSpaces',
                    permissionList = this.$store.state.rolesList;
                if (this.is_org) {
                    name = 'companySpaces'
                }
                if (permissionList.length && permissionList.indexOf('content') === -1) {
                    name = 'mediaScreens'
                }
                this.$router.push({ name: name })
            }
        },
        mounted() {
            this.getOrganizationList().then((data) => {
                this.organizationList = data
            })
        }
    }
</script>
<style scoped lang="less">
.layout-avatar {
    margin-left: 40px;
}
.badge-wrap {
    position: relative;
    display: inline-block;
    margin: 0 40px;
    width: 40px;
    height: 30px;
    .badge-text {
        display: block;
        line-height: 20px;
        height: 20px;
    }
}
.badge-wrap .ivu-badge-count {
    height: 14px !important;
    line-height: 14px;
}
.upgrade, .dropdown-qrcode {
    margin-left: 40px;
}
.campony-center {
    cursor: pointer;
    margin-left: 40px;
}
.qrcode-wrap {
    width: 170px;
    img {
        display: block;
        margin: 0 auto;
        width: 100px;
        height: 100px;
    }
    .info {
        display: block;
        padding: 10px 0;
        font-size: 14px;
        text-align: center;
        p {
            height: 18px;
            line-height: 18px;
        }
    }
}
#headerNav {
    display: inline-block;
    width: 100%;
    min-width: 400px;
    text-align: right;
    .username {
        display: inline-block;
        max-width: 120px;
        vertical-align: top;
    }
    .ivu-dropdown-menu {
        text-align: center;
    }
    /*@media screen and (max-width: 1000px) {
        .campony-center, .badge-wrap, .dropdown-qrcode {
            display: none;
        }
    }*/
}
</style>
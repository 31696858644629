<template>
    <div class="layout-logo-wrap pointer" @click="goTo2('spaces')">
        <img src="~assets/logo_icon.png">
        <img src="~assets/logo_text.png">
    </div>  
</template>
<script>
    import { navigation } from '@/mixins/index'

    export default {
        name: "",
        mixins: [navigation],
        components: {},
        props: [],
        data () {
            return {}
        },
        computed: {},
        methods: {},
        created() {}
    }
</script>
<style scoped lang="less">
.layout-logo-wrap {
    margin-bottom: 42px;
    padding: 24px 0 0 21px;
    img {
        display: inline-block;
        vertical-align: middle;
    }
}
</style>
<template>
    <div class="company-name line-overflow">{{ organization_name }}</div>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: [],
        data () {
            return {}
        },
        computed: {
            organization_name() {
                return this.$store.state.organization_name
            }
        },
        methods: {},
        created() {}
    }
</script>
<style scoped lang="less">
.company-name {
    padding: 0 0 20px 24px;
    height: 54px;
    width: 100%;
    font-size: 30px;
    font-weight: 700;
    line-height: 34px;
    color: #000;
}  
</style>